import axios from "axios";
import { API_URL } from "./../src/common/common.js";
import { authHeader } from "./auth-header";
import secureLocalStorage from "react-secure-storage";
axios.defaults.withCredentials = true;
const register = (formData) => {
  return axios.post(API_URL + "users/sign-up", formData);
};
const updateUserProfile = (formData) => {
  return axios.put(API_URL + "users/update-user", formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
const updatePassword = (formData) => {
  return axios.put(
    API_URL + "users/change-password",
    {
      oldPassword: formData.oldPassword,
      newPassword: formData.password,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
const forgotPassword = (email) => {
  return axios.post(API_URL + "users/send-forgot-password-email", {
    email: email,
  });
};
const resetPassword = (key, password) => {
  return axios.post(API_URL + "users/reset-password/" + key, {
    password: password,
  });
};
const verifyAccount = (id) => {
  return axios.get(API_URL + "users/verify-user/" + id);
};
const adminUserPassword = (
  key,
  // email
  // fullname,
  // location,
  // age,
  // company,
  password
  // isOtpEnabled
) => {
  return axios.post(API_URL + "admin/verify-user/" + key, {
    // firstName: fullname,
    // lastName: "",
    // isOtpEnabled: isOtpEnabled,
    // email: email,
    password: password,
    // confirmPassword: password,
    // userMetadata: {
    //   location: location,
    //   age: age,
    //   company: company,
    // },
  });
};
const verifyotp = (email, otp) => {
  return axios
    .post(API_URL + "users/validate-otp", { email, otp })
    .then((response) => {
      if (response.status == 200 && response.data.user) {
        secureLocalStorage.setItem("user", response.data.user.firstName);
        secureLocalStorage.setItem("token", response.data.token);
        secureLocalStorage.setItem("userId", response.data.user.id);
        secureLocalStorage.setItem("useruuId", response.data.user.uuid);
      }
      return response.data;
    });
};
const login = (email, password) => {
  secureLocalStorage.setItem("otpEmail", email);
  secureLocalStorage.setItem("otpPassword", password);
  return axios
    .post(
      API_URL + "users/validate-credentials",
      {
        email,
        password,
      },
      {
        withCredentials: true,
      }
    )
    .then((response) => {
      if (response.status == 200 && response.data.user) {
        secureLocalStorage.setItem("user", response.data.user.firstName);
        secureLocalStorage.setItem("token", response.data.token);
        secureLocalStorage.setItem("userId", response.data.user.id);
        secureLocalStorage.setItem("sessionId", response.data.sessionId);
        secureLocalStorage.setItem("useruuId", response.data.user.uuid);
        if (!response?.data?.user?.isAdmin) {
          axios
            .get(API_URL + "users/aws-credentials", {
              headers: { Authorization: `Bearer ${authHeader()}` },
            })
            .then((response1) => {
              secureLocalStorage.setItem(
                "SessionToken",
                response1.data.SessionToken
              );
              secureLocalStorage.setItem(
                "ACCESS_ID",
                response1.data.AccessKeyId
              );
              secureLocalStorage.setItem(
                "SECRET_KEY",
                response1.data.SecretAccessKey
              );
            });
        }
      }
      return response.data;
    });
};
const signupGoogle = (formData) => {
  return axios
    .post(API_URL + "users/sign-in-google", formData)
    .then((response) => {
      if (
        (response.status == 200 || response.status == 201) &&
        response.data.user
      ) {
        secureLocalStorage.setItem("user", response.data.user.firstName);
        secureLocalStorage.setItem("token", response.data.token);
        secureLocalStorage.setItem("userId", response.data.user.id);
        secureLocalStorage.setItem("sessionId", response.data.sessionId);
        secureLocalStorage.setItem("useruuId", response.data.user.uuid);
        if (!response?.data?.user?.isAdmin) {
          axios
            .get(API_URL + "users/aws-credentials", {
              headers: { Authorization: `Bearer ${authHeader()}` },
            })
            .then((response1) => {
              secureLocalStorage.setItem(
                "SessionToken",
                response1.data.SessionToken
              );
              secureLocalStorage.setItem(
                "ACCESS_ID",
                response1.data.AccessKeyId
              );
              secureLocalStorage.setItem(
                "SECRET_KEY",
                response1.data.SecretAccessKey
              );
            });
        }
      }
      return response.data;
    });
};
const logoutSession = (email, password) => {
  return axios
    .post(API_URL + "users/logout", {
      sessionId: Number(secureLocalStorage.getItem("sessionId")),
    })
    .then((response) => {
      return response;
    });
};
const logout = () => {
  secureLocalStorage.clear();
  window.location.reload(true);
};

export default {
  register,
  login,
  logout,
  updateUserProfile,
  updatePassword,
  verifyotp,
  forgotPassword,
  resetPassword,
  verifyAccount,
  adminUserPassword,
  logoutSession,
  signupGoogle,
};

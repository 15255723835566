export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_ADMIN = "LOGIN_SUCCESS_ADMIN";
export const LOGIN_SUCCESS_ORG_ADMIN = "LOGIN_SUCCESS_ORG_ADMIN";
export const LOGIN_OTP_REQUIRED = "LOGIN_OTP_REQUIRED";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USERTYPE = "USERTYPE";
export const ORGANIZATIONS = "ORGANIZATIONS";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const LOADING_MESSAGE = "LOADING_MESSAGE";

import AuthService from "../services/auth.service";
import {
  getAllOrgs,
  getUserTypes,
  postHubspotForm,
} from "../services/user.service";
import {
  LOGIN_FAIL,
  LOGIN_OTP_REQUIRED,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_ADMIN,
  LOGIN_SUCCESS_ORG_ADMIN,
  LOGOUT,
  ORGANIZATIONS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_MESSAGE,
  USERTYPE,
} from "./types";
import secureLocalStorage from "react-secure-storage";
export const register = (formData) => (dispatch) => {
  return AuthService.register(formData)
    .then((response) => {
      if (response.status == 200) {
        const message =
          response?.data?.message ||
          response?.message ||
          "User registered successfully";
        let datatosend = {
          fields: [
            {
              name: "firstname",
              value: formData.firstName,
            },
            {
              name: "lastname",
              value: formData.lastName,
            },
            {
              name: "phone",
              value: formData.phone,
            },
            {
              name: "country",
              value: formData.country,
            },
            {
              name: "state",
              value: formData.state,
            },
            {
              name: "city",
              value: formData.city,
            },
            {
              name: "industry",
              value: formData.industry,
            },
            {
              name: "message",
              value: "From webapp",
            },
            {
              name: "email",
              value: formData.email,
            },
          ],
        };
        if (formData.optIn) {
          datatosend["legalConsentOptions"] = {
            consent: {
              consentToProcess: true,
              // Boolean; Whether or not the visitor checked the Consent to process checkbox
              text: "Text that gives consent to process",
              // String; The text displayed to the visitor for the Consent to process checkbox
              communications: [
                // A list of details for the Consent to communicate for each subscription type included in the form
                {
                  value: true,
                  // Boolean; Whether or not the visitor checked the checkbox for this subscription type.
                  subscriptionTypeId: 25775581,
                  // Integer; The ID of the specific subscription type
                  text:
                    "Consent to communicate text for subscription type ID 25775581",
                  // String; The text displayed to the visitor for this specific subscription checkbox
                },
              ],
            },
          };
        }
        postHubspotForm(datatosend);
        dispatch({ type: REGISTER_SUCCESS });
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "success", isOpen: true },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: response.data.message,
            severity: "error",
            isOpen: true,
          },
        });
      }
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      // dispatch({ type: REGISTER_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const updateUserProfile = (formData, func) => (dispatch) => {
  formData.optIn = formData.optIn ? formData.optIn : false;
  return AuthService.updateUserProfile(formData)
    .then(async (response) => {
      const message =
        response?.data?.message || response?.message || "Profile Updated";
      if (response.status == 200) {
        secureLocalStorage.setItem("showTips", formData.showTooltips);
        secureLocalStorage.setItem(
          "user",
          response?.data?.user?.firstName || null
        );
        if (!secureLocalStorage.getItem("industry")) {
          let datatosend = {
            fields: [
              {
                name: "firstname",
                value: formData.firstName,
              },
              {
                name: "lastname",
                value: formData.lastName,
              },
              {
                name: "phone",
                value: "google signup",
              },
              {
                name: "country",
                value: formData.country,
              },
              {
                name: "state",
                value: formData.state,
              },
              {
                name: "city",
                value: formData.city,
              },
              {
                name: "industry",
                value: formData.industry,
              },
              {
                name: "message",
                value: "From webapp",
              },
              {
                name: "email",
                value: formData.email,
              },
            ],
          };
          if (formData.optIn) {
            datatosend["legalConsentOptions"] = {
              consent: {
                consentToProcess: true,
                // Boolean; Whether or not the visitor checked the Consent to process checkbox
                text: "Text that gives consent to process",
                // String; The text displayed to the visitor for the Consent to process checkbox
                communications: [
                  // A list of details for the Consent to communicate for each subscription type included in the form
                  {
                    value: true,
                    // Boolean; Whether or not the visitor checked the checkbox for this subscription type.
                    subscriptionTypeId: 25775581,
                    // Integer; The ID of the specific subscription type
                    text:
                      "Consent to communicate text for subscription type ID 25775581",
                    // String; The text displayed to the visitor for this specific subscription checkbox
                  },
                ],
              },
            };
          }
          await postHubspotForm(datatosend);
        }
        secureLocalStorage.setItem("industry", formData.industry);
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "success", isOpen: true },
        });
        func();
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "error", isOpen: true },
        });
      }
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password)
    .then((data) => {
      secureLocalStorage.setItem("email", data?.user ? data?.user?.email : "");
      secureLocalStorage.setItem(
        "userTips",
        JSON.stringify(data.userTips) || null
      );
      secureLocalStorage.setItem(
        "availableStorage",
        data?.storageDetail?.storageCapacity - data?.storageDetail?.usedStorage
      );
      secureLocalStorage.setItem(
        "storageCapacity",
        data?.storageDetail?.storageCapacity
      );
      secureLocalStorage.setItem(
        "restrictions",
        data?.restrictions ? JSON.stringify(data?.restrictions) : ""
      );
      secureLocalStorage.setItem(
        "typeID",
        data?.user ? data?.user?.typeID : ""
      );
      if (data?.user?.priority)
        secureLocalStorage.setItem(
          "priority",
          data?.user ? data?.user?.priority : ""
        );
      if (data.message.includes("OTP")) {
        dispatch({ type: LOGIN_OTP_REQUIRED });
      } else {
        if (data?.user?.isAdmin) {
          getUserTypes().then((res) => {
            let types = {};
            res.data.userTypes.rows.forEach((singletype) => {
              types[singletype.type_id] = singletype.type_name;
            });
            dispatch({
              type: USERTYPE,
              payload: types,
            });
          });
          getAllOrgs().then((res) => {
            let types = {};
            res.data.organizations.forEach((singletype) => {
              types[singletype.organizationID] = singletype.organizationName;
            });
            types[null] = "No Organization";
            dispatch({
              type: ORGANIZATIONS,
              payload: types,
            });
          });
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          secureLocalStorage.setItem("isAdmin", 1);
          dispatch({ type: LOGIN_SUCCESS_ADMIN });
        } else {
          secureLocalStorage.setItem(
            "industry",
            data.user?.industry ? data.user?.industry : ""
          );
          secureLocalStorage.setItem("showTips", data.user?.showTooltips);
          secureLocalStorage.setItem("eula", data.user?.eula);
          secureLocalStorage.setItem(
            "organization",
            data.user?.canCreateOrganization
          );
          secureLocalStorage.setItem(
            "organizationID",
            data.user?.organizationID
          );
          secureLocalStorage.setItem(
            "organizationLimit",
            data.organizationDetails?.limit
          );
          secureLocalStorage.setItem(
            "organizationName",
            data.organizationDetails?.organizationName
          );
          if (data.organizationDetails?.logoUrl) {
            secureLocalStorage.setItem(
              "organizationLogo",
              data.organizationDetails?.logoUrl
            );
          }
          if (data.organizationDetails?.style) {
            if (
              typeof JSON.parse(data.organizationDetails?.style) === "object" &&
              JSON.parse(data.organizationDetails?.style)
            ) {
              secureLocalStorage.setItem(
                "organizationColor",
                JSON.parse(data.organizationDetails?.style).header
              );
              secureLocalStorage.setItem(
                "organizationText",
                JSON.parse(data.organizationDetails?.style).text
              );
            }
          }
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          if (data?.user?.isOrganizationAdmin) {
            secureLocalStorage.setItem("isOrganizationAdmin", 1);
            dispatch({
              type: LOGIN_SUCCESS_ORG_ADMIN,
              payload: { user: data },
            });
          } else {
            dispatch({ type: LOGIN_SUCCESS, payload: { user: data } });
          }
          if (secureLocalStorage.getItem("reloadMap")) {
            secureLocalStorage.setItem("reloadMap", 0);
            window.location.reload();
          }
        }
      }
      return Promise.resolve();
    })
    .catch((error) => {
      let message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({ type: LOGIN_FAIL });

      if (message.toLowerCase().includes("verify")) {
        message = "Please check your email for the verification link";
      }
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const signupGoogle = (formData) => (dispatch) => {
  return AuthService.signupGoogle(formData)
    .then((data) => {
      secureLocalStorage.setItem("email", data?.user ? data?.user?.email : "");
      secureLocalStorage.setItem(
        "userTips",
        JSON.stringify(data.userTips) || null
      );
      secureLocalStorage.setItem(
        "availableStorage",
        data?.storageDetail?.storageCapacity - data?.storageDetail?.usedStorage
      );
      secureLocalStorage.setItem(
        "storageCapacity",
        data?.storageDetail?.storageCapacity
      );
      secureLocalStorage.setItem(
        "restrictions",
        data?.restrictions ? JSON.stringify(data?.restrictions) : ""
      );
      secureLocalStorage.setItem(
        "typeID",
        data?.user ? data?.user?.typeID : ""
      );
      if (data.message.includes("OTP")) {
        dispatch({ type: LOGIN_OTP_REQUIRED });
      } else {
        if (data?.user?.isAdmin) {
          getUserTypes().then((res) => {
            let types = {};
            res.data.userTypes.rows.forEach((singletype) => {
              types[singletype.type_id] = singletype.type_name;
            });
            dispatch({
              type: USERTYPE,
              payload: types,
            });
          });
          getAllOrgs().then((res) => {
            let types = {};
            res.data.organizations.forEach((singletype) => {
              types[singletype.organizationID] = singletype.organizationName;
            });
            types[null] = "No Organization";
            dispatch({
              type: ORGANIZATIONS,
              payload: types,
            });
          });
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          secureLocalStorage.setItem("isAdmin", 1);
          dispatch({ type: LOGIN_SUCCESS_ADMIN });
        } else {
          secureLocalStorage.setItem(
            "industry",
            data.user?.industry ? data.user?.industry : ""
          );
          secureLocalStorage.setItem("showTips", data.user?.showTooltips);
          secureLocalStorage.setItem("eula", data.user?.eula);
          secureLocalStorage.setItem(
            "organization",
            data.user?.canCreateOrganization
          );
          secureLocalStorage.setItem(
            "organizationID",
            data.user?.organizationID
          );
          secureLocalStorage.setItem(
            "organizationLimit",
            data.organizationDetails?.limit
          );
          secureLocalStorage.setItem(
            "organizationName",
            data.organizationDetails?.organizationName
          );
          if (data.organizationDetails?.logoUrl) {
            secureLocalStorage.setItem(
              "organizationLogo",
              data.organizationDetails?.logoUrl
            );
          }
          if (data.organizationDetails?.style) {
            if (
              typeof JSON.parse(data.organizationDetails?.style) === "object" &&
              JSON.parse(data.organizationDetails?.style)
            ) {
              secureLocalStorage.setItem(
                "organizationColor",
                JSON.parse(data.organizationDetails?.style).header
              );
              secureLocalStorage.setItem(
                "organizationText",
                JSON.parse(data.organizationDetails?.style).text
              );
            }
          }
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          if (data?.user?.isOrganizationAdmin) {
            secureLocalStorage.setItem("isOrganizationAdmin", 1);
            dispatch({
              type: LOGIN_SUCCESS_ORG_ADMIN,
              payload: { user: data },
            });
          } else {
            dispatch({ type: LOGIN_SUCCESS, payload: { user: data } });
          }
          if (secureLocalStorage.getItem("reloadMap")) {
            secureLocalStorage.setItem("reloadMap", 0);
            window.location.reload();
          }
        }
      }
      return Promise.resolve();
    })
    .catch((error) => {
      let message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({ type: LOGIN_FAIL });

      if (message.toLowerCase().includes("verify")) {
        message = "Please check your email for the verification link";
      }
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const resendotp = (email, password) => (dispatch) => {
  return AuthService.login(email, password)
    .then((data) => {
      if (data.message.includes("OTP")) {
        secureLocalStorage.setItem("otpEmail", email);
        secureLocalStorage.setItem("otpPassword", password);
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: `An One Time Password (OTP)" has been sent to your email ${email}.`,
            severity: "success",
            isOpen: true,
          },
        });
      } else {
        if (data?.user?.isAdmin) {
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          secureLocalStorage.setItem("isAdmin", 1);
          dispatch({ type: LOGIN_SUCCESS_ADMIN });
        } else {
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          if (data?.user?.isOrganizationAdmin) {
            secureLocalStorage.setItem("isOrganizationAdmin", 1);
            dispatch({
              type: LOGIN_SUCCESS_ORG_ADMIN,
              payload: { user: data },
            });
          } else {
            dispatch({ type: LOGIN_SUCCESS, payload: { user: data } });
          }
        }
      }
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({ type: LOGIN_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const verifyotp = (username, password) => (dispatch) => {
  return AuthService.verifyotp(username, password)
    .then((data) => {
      secureLocalStorage.setItem("otpEmail", username);
      secureLocalStorage.setItem("otpPassword", password);

      if (data.message.includes("OTP")) {
        dispatch({ type: LOGIN_OTP_REQUIRED });
      } else {
        if (data?.user?.isAdmin) {
          getUserTypes().then((res) => {
            let types = {};
            res.data.userTypes.rows.forEach((singletype) => {
              types[singletype.type_id] = singletype.type_name;
            });
            dispatch({
              type: USERTYPE,
              payload: types,
            });
          });
          getAllOrgs().then((res) => {
            let types = {};
            res.data.organizations.forEach((singletype) => {
              types[singletype.organizationID] = singletype.organizationName;
            });
            types[null] = "No Organization";
            dispatch({
              type: ORGANIZATIONS,
              payload: types,
            });
          });
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          secureLocalStorage.setItem("isAdmin", 1);
          dispatch({ type: LOGIN_SUCCESS_ADMIN });
        } else {
          secureLocalStorage.setItem(
            "industry",
            data.user?.industry ? data.user?.industry : ""
          );
          secureLocalStorage.setItem("eula", data.user?.eula);
          secureLocalStorage.setItem("showTips", data.user?.showTooltips);
          secureLocalStorage.setItem(
            "organization",
            data.user?.isOrganizationAdmin
          );
          secureLocalStorage.setItem(
            "organizationID",
            data.user?.organizationID
          );
          secureLocalStorage.setItem(
            "organizationName",
            data.organizationDetails?.organizationName
          );
          if (data.organizationDetails?.logoUrl) {
            secureLocalStorage.setItem(
              "organizationLogo",
              data.organizationDetails?.logoUrl
            );
          }
          if (data.organizationDetails?.style) {
            if (
              typeof JSON.parse(data.organizationDetails?.style) === "object" &&
              JSON.parse(data.organizationDetails?.style)
            ) {
              secureLocalStorage.setItem(
                "organizationColor",
                JSON.parse(data.organizationDetails?.style).header
              );
              secureLocalStorage.setItem(
                "organizationText",
                JSON.parse(data.organizationDetails?.style).text
              );
            }
          }
          secureLocalStorage.removeItem("otpEmail");
          secureLocalStorage.removeItem("otpPassword");
          if (data?.user?.isOrganizationAdmin) {
            secureLocalStorage.setItem("isOrganizationAdmin", 1);
            dispatch({
              type: LOGIN_SUCCESS_ORG_ADMIN,
              payload: { user: data },
            });
          } else {
            dispatch({ type: LOGIN_SUCCESS, payload: { user: data } });
          }
        }
      }
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({ type: LOGIN_FAIL });
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const updatePassword = (formData) => (dispatch) => {
  return AuthService.updatePassword(formData)
    .then((response) => {
      const message =
        response?.data?.message || response?.message || "Password Updated";
      if (response.status == 200) {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "success", isOpen: true },
        });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "error", isOpen: true },
        });
      }
      return Promise.resolve();
    })
    .catch((error) => {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, severity: "error", isOpen: true },
      });
      return;
    });
};
export const logout = () => (dispatch) => {
  AuthService.logoutSession();
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};

import { SET_MESSAGE, CLEAR_MESSAGE, LOADING_MESSAGE } from "../actions/types";

const initialState = {
  message: {},
  isLoading: false
};

export default function (state = initialState, action) {
  const { type, payload, isLoading } = action;

  switch (type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: payload,
        isLoading: false
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: {},
        isLoading: false
      };

    case LOADING_MESSAGE:
      return {
        ...state,
        isLoading: isLoading
      };

    default:
      return state;
  }
}

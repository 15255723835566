import axios from "axios";
import { authHeader, getUserId } from "./auth-header";
import { API_URL } from "./../src/common/common.js";
axios.defaults.withCredentials = true;
export function getUserDetail() {
  return axios.get(API_URL + "users/get-user-details", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getDashCounts() {
  return axios.get(API_URL + "users/user-count", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getUserActivity(startMonth, endMonth) {
  return axios.get(
    API_URL +
      "admin/dashboard/user-activity?startMonth=" +
      startMonth +
      "&endMonth=" +
      endMonth,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function getPolicy() {
  return axios.get(API_URL + "policy", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllUsers() {
  return axios.get(API_URL + "admin/list-users", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllOrgs() {
  return axios.get(API_URL + "admin/organizations", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllTips() {
  return axios.get(API_URL + "admin/user-tips", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllAdmins() {
  return axios.get(API_URL + "admin/list-users?isAdmin=true", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllOrganization() {
  return axios.get(API_URL + "admin/organizations", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllIssues() {
  return axios.get(API_URL + "admin/list-issues", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllErrors(date1, date2, email, page, pageSize) {
  return axios.get(
    API_URL +
      "admin/general-errors/?startDate=" +
      date1 +
      "&endDate=" +
      date2 +
      "&email=" +
      email +
      "&page=" +
      page +
      "&size=" +
      pageSize,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function getPlans() {
  return axios.get(API_URL + "payment/stripe/all-packages", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getCurrentPlans() {
  return axios.get(API_URL + "payment/stripe/my-subscriptions", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getEulaHistory() {
  return axios.get(API_URL + "policy/history", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getFileLogs(date1, date2, email, page, pageSize) {
  return axios.get(
    API_URL +
      "admin/file-logs/?startDate=" +
      date1 +
      "&endDate=" +
      date2 +
      "&email=" +
      email +
      "&page=" +
      page +
      "&pageSize=" +
      pageSize,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function getAllLogs(date1, date2, email, page, pageSize) {
  return axios.get(
    API_URL +
      "admin/user-logs/?startDate=" +
      date1 +
      "&endDate=" +
      date2 +
      "&email=" +
      email +
      "&page=" +
      page +
      "&pageSize=" +
      pageSize,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function getInactiveUsers() {
  return axios.get(API_URL + "admin/list-users?active=false", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}

export function getAllProjects() {
  return axios.get(API_URL + "projects/projects", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getProjectsDetails() {
  return axios.get(API_URL + "projects/projects-v2", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getBulkLayers(body) {
  return axios.post(API_URL + "projects/layers", body, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getAllProjectsFolders(projectId) {
  return axios.get(API_URL + "projects/project-details/" + projectId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getSingleLayer(layerId) {
  return axios.get(API_URL + "projects/layers/" + layerId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getSingleThumbnail(layerId) {
  return axios.get(API_URL + "projects/thumbnail/" + layerId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getSingleAttr(layerId) {
  return axios.get(API_URL + "projects/features/" + layerId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getOrganization(id) {
  return axios.get(API_URL + "organizations/" + id, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getPendingInvites(id) {
  return axios.get(API_URL + "organizations/" + id + "/pending-invites", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getOrganizationProjects(id) {
  return axios.get(API_URL + "organizations/" + id + "/projects", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getProjectsUsers(id, projectId) {
  return axios.get(API_URL + "organizations/" + id + "/projects/" + projectId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export const updateShare = (formData) => {
  return axios.put(API_URL + "projects/share/zoomTo", formData);
};
export function getProjectsShare(projectId) {
  return axios.post(
    API_URL + "projects/get-project-share-urls",
    { projectId: projectId },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export const createShareToken = (projectId, expDate, layerId, download) => {
  return axios.post(
    API_URL + "projects/create-share-url",
    {
      projectId: projectId,
      expirationDate: expDate,
      layerId: layerId,
      download: download,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const validateShareToken = (token) => {
  return axios.post(
    API_URL + "projects/validate-share-url",
    { shareToken: token },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export function deleteShareToken(projectId, token) {
  return axios
    .delete(
      API_URL + "projects/delete-share-url",

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
        data: { shareToken: token, projectId: projectId },
      }
    )
    .catch((error) => {});
}
export function updateOrganizationAdmin(id, uuid, data) {
  return axios
    .patch(API_URL + "organizations/" + id + "/user/" + uuid, data, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}
export const updateProjectPermissions = (id, projectId, datatosend) => {
  // let datatosend = {
  //   organizationName: organizationName,
  //   organizationDescription: organizationDescription,
  // };
  return axios.put(
    API_URL + "organizations/" + id + "/projects/" + projectId + "/permissions",
    datatosend,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export function getAllLayerMedia(layerId) {
  return axios.get(API_URL + "projects/getMedia/" + layerId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function downloadFolder(folder_id) {
  return axios.get(API_URL + `projects/download-folder/${folder_id}`, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getFeatureMedia(layerid, featureId) {
  return axios.get(
    API_URL +
      "features/feature-media/" +
      layerid +
      `${featureId ? `?featureId=${featureId}` : ""}`,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function getFolderInfo(folderId) {
  return axios.get(API_URL + "projects/folder/" + folderId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function getFeatureAttr(layerId, featureId) {
  return axios.get(
    API_URL +
      "features/" +
      layerId +
      "/attribute" +
      `${featureId ? `?featureId=${featureId}` : ""}`,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function updateLayerAttributes(attributes, layerId, featureId) {
  return axios
    .patch(
      API_URL +
        "features/" +
        layerId +
        "/attribute" +
        `${featureId ? `?featureId=${featureId}` : ""}`,
      attributes,
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function getFeatureAttrFolder(folderId) {
  return axios.get(API_URL + "features/folder/" + folderId + "/attribute", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function updateLayerAttributesFolder(attributes, folderId) {
  return axios
    .patch(API_URL + "features/folder/" + folderId + "/attribute", attributes, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}
export function getAllFolderLayers(folderId) {
  return axios.get(API_URL + "projects/get-layers/" + folderId, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function addNewProject(body) {
  return axios.post(API_URL + "projects/create-project", body, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function addNewFolder(projectId, body) {
  return axios
    .post(API_URL + "projects/create-folder/" + projectId, body, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}
export function addNewSubFolder(projectId, body) {
  return axios
    .post(API_URL + "projects/create-folder/" + projectId, body, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}
export function addNewLayer(layer, projectId, folderId, layerType) {
  let body;
  if (layerType == 3 || layerType == 4 || layerType == 6) {
    body = {
      folderId: folderId,
      shapefile: layer,
      layerType: layerType,
    };
  } else if (layerType == 2) {
    body = {
      folderId: folderId,
      tiff: layer,
      layerType: layerType,
    };
  } else if (layerType == 5) {
    body = {
      folderId: folderId,
      file: layer,
      layerType: layerType,
    };
  } else if (layerType == 1) {
    body = {
      folderId: folderId,
      geojson: layer,
      layerType: layerType,
    };
  } else if (layerType == 7) {
    body = {
      folderId: folderId,
      wms: layer,
      layerType: layerType,
      // displayOrder: 1,
    };
  } else if (layerType == 8) {
    body = {
      folderId: folderId,
      misc: layer,
      layerType: layerType,
    };
  }
  return axios
    .post(API_URL + "projects/create-layer-s3/" + projectId, body, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}
export function deleteProject(projectId) {
  return axios
    .delete(API_URL + "projects/delete-project-s3/" + projectId, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}

export function deleteFolder(projectId, folderId) {
  return axios
    .delete(
      API_URL + "projects/delete-folder-s3",

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
        data: {
          projectId: projectId,
          folderId: folderId,
        },
      }
    )
    .catch((error) => {});
}
export function deleteLayer(projectId, folderId, layerId) {
  return axios
    .delete(
      API_URL + "projects/delete-layer-s3",

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
        data: {
          projectId: projectId,
          folderId: folderId,
          layerId: layerId,
        },
      }
    )
    .catch((error) => {});
}

export function updateProject(projectName, projectId) {
  return axios
    .patch(
      API_URL + "projects/update-project/" + projectId,
      {
        name: projectName,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function updateFolder(folderName, projectId, folderId) {
  return axios
    .patch(
      API_URL + "projects/update-folder/" + folderId,
      {
        name: folderName,
        projectId: projectId,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function updateFolderDateCollected(
  folderName,
  projectId,
  folderId,
  dateCollected
) {
  return axios
    .patch(
      API_URL + "projects/update-folder/" + folderId,
      {
        name: folderName,
        projectId: projectId,
        dateCollected: dateCollected,
        // altitudeMode: "clampToGround",
        // tilesetHeight: 41,
        // tilesetHeightDefault: -13,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function updateFolderBulkAltitude(folderId, payLoad) {
  return axios
    .patch(
      API_URL + "projects/update-folder/" + folderId,

      payLoad,
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function updateReviewStatus(layerid, payLoad) {
  return axios
    .patch(
      API_URL + "projects/add-layer-status/" + layerid,

      payLoad,
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function updateLayer(layerName, folderId, layerId) {
  return axios
    .patch(
      API_URL + "projects/update-layer/" + layerId,
      {
        name: layerName,
        folderId: folderId,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function updateLayerDateCollected(
  layerName,
  folderId,
  layerId,
  dateCollected
) {
  return axios
    .patch(
      API_URL + "projects/update-layer/" + layerId,
      {
        name: layerName,
        folderId: folderId,
        dateCollected: dateCollected,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function updateLayerShow(layerShow, folderId, layerId) {
  return axios
    .patch(
      API_URL + "projects/update-layer/" + layerId,
      {
        folderId: folderId,
        displayStatus: layerShow,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function updateFolderShow(layerShow, folderId) {
  return axios
    .patch(
      API_URL + "projects/update-layers/" + folderId,
      {
        displayStatus: layerShow,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function moveFolder(
  folderId,
  projectId,
  updatedFolderId,
  updatedProjectId
) {
  let updatedFolderIdStr = "";
  if (updatedFolderId) {
    updatedFolderIdStr = "&folderId=" + updatedFolderId;
  }
  return axios
    .patch(
      API_URL +
        "projects/update-folder/" +
        folderId +
        "?projectId=" +
        updatedProjectId +
        updatedFolderIdStr,
      {
        projectId: projectId,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function moveLayer(layerId, folderId, updatedFolderId) {
  return axios
    .patch(
      API_URL +
        "projects/update-layer/" +
        layerId +
        "?updatedFolderId=" +
        updatedFolderId,
      {
        folderId: folderId,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function resendVerification(email) {
  return axios
    .post(API_URL + "users/resend-verfication-email", {
      email: email,
    })
    .catch((error) => {});
}
export function restoreOrganization(orgId) {
  return axios
    .post(
      API_URL + "admin/restore-organization/" + orgId,
      {},
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function deleteUser(userId) {
  return axios
    .delete(
      API_URL + "admin/deactivate-users",

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
        data: {
          ids: [userId],
        },
      }
    )
    .catch((error) => {});
}
export function deleteOrganization(orgId) {
  return axios
    .delete(API_URL + "admin/delete-organization/" + orgId, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {});
}
export function hardDeleteUser(userId) {
  return axios
    .delete(
      API_URL + "admin/delete-user/" + userId,

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function activateUser(userId) {
  return axios
    .post(
      API_URL + "admin/activate-users",
      {
        ids: [userId],
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export const createUser = (formData) => {
  return axios.post(API_URL + "admin/create-user", formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const createAdminOrg = (formData) => {
  return axios.post(API_URL + "admin/organizations", formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const updateAdminOrg = (formData, id) => {
  return axios.patch(API_URL + "admin/organizations/" + id, formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const updateUser = (uuid, formData) => {
  return axios.put(API_URL + "admin/edit-user/" + uuid, formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const updateKmlFeature = (layerName, formData) => {
  return axios.put(
    API_URL + "projects/layers/" + layerName + "/featurestyle",
    formData,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const updateRestrictions = (id, formData) => {
  return axios.patch(API_URL + "admin/edit-userType/" + id, formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const updatePlan = (uuid, formData) => {
  return axios.put(
    API_URL + "payment/stripe/update-package/" + uuid,
    formData,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const cancelSubscription = (formData) => {
  return axios.post(
    API_URL + "payment/stripe/cancel-subscription",
    {},
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const addUserInOrganization = (orgId, uid) => {
  return axios.get(API_URL + "organizations/" + orgId + "/user/" + uid);
};
export const createOrganization = (formData) => {
  return axios.post(API_URL + "organizations", formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const updateOrganization = (formData, id) => {
  return axios.put(API_URL + "organizations/" + id, formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const inviteOrganizationUser = (
  userEmail,
  organizationID,
  firstName,
  lastName
) => {
  let data_to_send =
    firstName == ""
      ? {
          userEmail: userEmail,
          organizationID: organizationID,
        }
      : {
          userEmail: userEmail,
          organizationID: organizationID,
          firstName,
          lastName,
        };
  return axios.post(API_URL + "organizations/invite-user", data_to_send, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};

export function deleteUserOrganizaton(userId, organizationId) {
  return axios
    .delete(
      API_URL + "organizations/" + organizationId + "/user/" + userId,

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function updatePointCloudLayerAttributes(attributes, layerId) {
  return axios
    .patch(
      API_URL + "projects/update-layer-attributes/" + layerId,
      attributes,
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}

export function addNewMedia(data, layerid, featureId) {
  var bodyFormData = new FormData();
  data.map((file) => {
    {
      bodyFormData.append("file", file);
    }
  });

  return axios
    .post(
      API_URL +
        "features/feature-media/" +
        layerid +
        `${featureId ? `?featureId=${featureId}` : ""}`,
      bodyFormData,
      {
        headers: {
          Authorization: `Bearer ${authHeader()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .catch((error) => {});
}

export function deleteMedia(mediaId, layerid, featureId) {
  return axios
    .delete(
      API_URL +
        "features/feature-media/" +
        layerid +
        `${featureId ? `?featureId=${featureId}` : ""}`,

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
        data: {
          featureId: featureId,
          featureMediaId: mediaId,
        },
      }
    )
    .catch((error) => {});
}

export function reportIssue(formData) {
  return axios
    .post(
      API_URL + "users/user-query",
      {
        title: formData.title,
        description: formData.description,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function deleteUserSelf(userId) {
  return axios
    .delete(API_URL + "users/delete-user/" + userId)
    .catch((error) => {});
}
export const updatePolicy = (policy, policyId) => {
  return axios.put(
    API_URL + "policy/" + policyId,
    {
      value: policy,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const updateKmlSld = (layerName, sldBody) => {
  return axios.put(
    API_URL + "geoserver/layers/" + layerName + "/style",
    {
      sldBody: sldBody,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const updateIssue = (status, issueId) => {
  return axios.put(
    API_URL + "admin/update-issue/" + issueId,
    {
      status: status,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};

export function getUserLocation() {
  return axios.get(
    `https://ipfind.co/me?auth=ba894bc6-476b-4b2c-b412-b54a4a720c9b`
  );
}
export const getUserTypes = () => {
  return axios.get(API_URL + "admin/user-types", {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export const createTip = (header, details, userType) => {
  return axios.post(
    API_URL + "admin/user-tips",
    {
      header: header,
      details: details,
      userType: userType,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
};
export const updateTip = (tipId, header, details, userType) => {
  let datatosend = {
    header: header,
    details: details,
    userType: userType,
  };

  return axios.put(API_URL + "admin/update-tip/" + tipId, datatosend, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export function deleteTip(tipId) {
  return axios
    .delete(
      API_URL + "admin/delete-tip/" + tipId,

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function disableTips() {
  return axios.put(
    API_URL + "users/enable-tips",
    {
      showTooltips: false,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function enableEula() {
  return axios.put(
    API_URL + "users/enable-eula",
    {
      eula: true,
    },
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}
export function getSignedUrl(body) {
  return axios
    .post(API_URL + "projects/get-presigned-url", body, {
      headers: { Authorization: `Bearer ${authHeader()}` },
    })
    .catch((error) => {
      console.log(error);
    });
}
export function uploadSignedFile(url, body, config) {
  return axios.put(url, body, config).catch((error) => {
    console.log(error);
  });
}
export const updateVectorStyles = (formData) => {
  return axios.put(API_URL + "geoserver/layers/style", formData);
};
export const updatePointIcon = (icon, formData) => {
  return axios.put(API_URL + "geoserver/layers/style/" + icon, formData);
};
export const displayOrder = (formData) => {
  return axios.patch(API_URL + "projects/display-order", formData, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
};
export function stripeCheckout(body) {
  return axios.post(API_URL + "payment/stripe/checkout", body, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function fetchUrlMeta(body) {
  return axios.post(API_URL + "meta-tags", body, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function stripeManage() {
  return axios.post(API_URL + "payments/stripe/manage", null, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}

export function getImagesFolder(projectId, folderId) {
  return axios.get(
    API_URL +
      "projects/images?projectId=" +
      projectId +
      "&folderId=" +
      folderId,
    {
      headers: { Authorization: `Bearer ${authHeader()}` },
    }
  );
}

export function postImgTag(body) {
  return axios.post(API_URL + "projects/image-tag", body, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
  // .then((res) => {
  //   console.log("image-tag-Resp", res);
  // })
  // .catch((error) => {
  //   console.log(error);
  // });
}

export function editVector(fileName, layerId) {
  return axios
    .patch(
      API_URL + "projects/update-layer-vector/" + layerId,
      {
        data: fileName,
      },
      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function deletePending(userId) {
  return axios
    .delete(
      API_URL + "organizations/pending-invites/" + userId,

      {
        headers: { Authorization: `Bearer ${authHeader()}` },
      }
    )
    .catch((error) => {});
}
export function getAdminUser(email) {
  return axios.get(API_URL + "admin/user-info/" + email, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}
export function postHubspotForm(body) {
  return axios.post(API_URL + "hubspot", body, {
    headers: { Authorization: `Bearer ${authHeader()}` },
  });
}

import {
  LOGIN_FAIL, LOGIN_OTP_REQUIRED, LOGIN_SUCCESS,
  LOGIN_SUCCESS_ADMIN,
  LOGIN_SUCCESS_ORG_ADMIN, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS, USERTYPE,ORGANIZATIONS
} from "../actions/types";
import  secureLocalStorage  from  "react-secure-storage";
const user = secureLocalStorage.getItem("user");
const isAdmin = secureLocalStorage.getItem("isAdmin");
const isOrganizationAdmin = secureLocalStorage.getItem("isOrganizationAdmin");
const initialState = user
  ? {
    isLoggedIn: true,
    isOtp: false,
    isAdmin: isAdmin == 1 ? true : false,
    isOrganizationAdmin: isOrganizationAdmin == 1 ? true : false,
    userTypes: null,
    organizations:null
  }
  : { isLoggedIn: false, isOtp: false, isAdmin: false, isOrganizationAdmin: false, userTypes: null,organizations:null };

  export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: false,
        isOrganizationAdmin: false,
        user: payload.user,
      };
    case USERTYPE:
      return {
        ...state,

        userTypes: payload,
      };
    case ORGANIZATIONS:
      return {
        ...state,

        organizations: payload,
      };
    case LOGIN_SUCCESS_ADMIN:
      return {
        ...state,
        isAdmin: true,
        isOrganizationAdmin: false,
        isLoggedIn: true,
      };
    case LOGIN_SUCCESS_ORG_ADMIN:
      return {
        ...state,
        isAdmin: false,
        isOrganizationAdmin: true,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_OTP_REQUIRED:
      return {
        ...state,
        isOtp: true,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      secureLocalStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        isAdmin: false,
        isOrganizationAdmin: false,
        isOtp: false,
        user: null,
      };
    default:
      return state;
  }
}
